import { useToken } from "@chakra-ui/react";
import adze from "adze";
import axios from "axios";
import { useQuery } from "react-query";

export const logger = () => {
  return adze({ useEmoji: true });
};

export const debugLog = (item: any) => {
  adze({ useEmoji: true }).debug(item);
};

export const useThemeTokens = (theme: string) => {
  const [_100, _200, _300, _400, _500, _600, _700, _800, _900] = useToken(
    "colors",
    [
      `${theme}.100`,
      `${theme}.200`,
      `${theme}.300`,
      `${theme}.400`,
      `${theme}.500`,
      `${theme}.600`,
      `${theme}.700`,
      `${theme}.800`,
      `${theme}.900`,
    ]
  );

  return {
    "100": _100,
    "200": _200,
    "300": _300,
    "400": _400,
    "500": _500,
    "600": _600,
    "700": _700,
    "800": _800,
    "900": _900,
  };
};

export const usePartnerData = (personId: string | number, enabled: boolean) => {
  const query = useQuery(
    ["partnerDetails", personId],
    async () => {
      const data = await axios.get("/api/getPartnerData", {
        params: {
          businessId: personId,
        },
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );

  return {
    ...query,
    exists: query.data?.status === 200,
  };
};

//export const usePartnerDashData = (personId: string | number, enabled: boolean) => {
//    const query = useQuery(
//        ["partnerDashboard", personId],
//        async () => {
//            const data = await axios.post("/api/partnerDashboard", {
//                params: {
//                    businessId: personId,
//                },
//            });
//            return data;
//        },
//        { enabled: enabled }
//    );

//    return {
//        ...query,
//        exists: query.data?.status === 200,
//    };
//};

export const usePartnerDashboard = (businesses: (string | number)[]) => {
  //const query = useQuery(
  //  ["partnerDashboard", businesses],
  //  async () => {
  //    const data = await axios.post("/api/partnerDashboard", { businesses });
  //    return data.data.Data;
  //  },
  //  { enabled: businesses?.length > 0 }
  //);

  //return {
  //  ...query,
  //};
};
