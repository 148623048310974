import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import theme from "../theme";
import DashboardNav from "../components/DashboardNav";
import {
  RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
} from "recoil";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "rsuite/dist/rsuite.min.css";
import NextNprogress from "nextjs-progressbar";
import { SWRConfig } from "swr";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axios from "axios";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "../styles/primeReactOverride.scss";
import PrimeReact from "primereact/api";
import Head from "next/head";
import Script from "next/script";
import * as gtag from "../utils/gtag";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { useRouter } from "next/router";
import { ReactQueryDevtools } from "react-query/devtools";
import { NextUIProvider } from "@nextui-org/react";
const queryClient = new QueryClient();
function MyApp({ Component, pageProps }: AppProps) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.tz.setDefault(dayjs.tz.guess());
  const router = useRouter();
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleRouteChange = (url: any) => {
        gtag.pageview(url);
      };
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router.events]);

  return (
    <QueryClientProvider client={queryClient}>

      <SWRConfig
        value={{
          fetcher: (url: string) => axios.get(url).then((res) => res.data),
          refreshWhenHidden: true,
          errorRetryInterval: 100,
        }}
      >
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=G-NFSCPVRPWD`}
          id="Gtag1"
        />
        <Script
          id="Gtag2"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-NFSCPVRPWD', {
            page_path: window.location.pathname,
          });
          `,
          }}
        />
        <RecoilRoot>
          <Head>
            <title>Econic Seller Dashboard</title>
            <meta
              name="keywords"
              content="Econic, Econic dashboard sign in, Econic Dashboard"
            ></meta>
            <link rel="icon" href="/logoOnlyLight.svg"></link>
          </Head>
          <ChakraProvider theme={theme}>
            <DashboardNav />
            <NextNprogress color="#F7941d" />
            <Component {...pageProps} />
          </ChakraProvider>
        </RecoilRoot>
      </SWRConfig>
    </QueryClientProvider>
  );
}

export default MyApp;
