import { motion } from "framer-motion";
import {
  Avatar,
  Badge,
  Flex,
  Text,
  useColorMode,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Heading,
  ModalCloseButton,
  Popover,
  PopoverContent,
  PopoverTrigger as OrigPopoverTrigger,
  PopoverFooter,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  useDisclosure,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberInputStepper,
  NumberDecrementStepper,
  Box,
  useToast,
  Img,
  VStack,
  Icon,
} from "@chakra-ui/react";
import {
  isLoggedInState,
  totalLocationDataState,
  currentCartDataState,
} from "../recoilStates";
import NewItemSVG from "../assets/images/icon-new-item.svg";
import { Camera, Trash } from "phosphor-react";
import { useState, useRef } from "react";
import { useRecoilState } from "recoil";
import dayjs from "dayjs";
export const MotionFlex = motion(Flex);

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;
const CartItem = (props: any) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const qtyInputRef = useRef<any>();
  const [currentCartData, setCurrentCartData] =
    useRecoilState(currentCartDataState);
  const toast = useToast();
  const [startPoint, setStartPoint] = useState<any>();

  const handleDelete = () => {
    var tempData = {
      total: currentCartData.total - props.price * props.quantity,
      products: [...currentCartData.products],
    };
    if (props.index == tempData.products.length) {
      tempData.products.pop();
    } else tempData.products.splice(props.index, 1);
    setCurrentCartData(tempData);
    onClose();
    toast({
      title: `Removed ${qtyInputRef.current.value}x ${props.name} from the cart`,
      variant: "solid",
      isClosable: true,
      status: "error",
      duration: 4000,
      position: "top-right",
    });
  };

  const handleUpdate = () => {
    var tempData = {
      total: currentCartData.products
        .map((product: any) =>
          product.index == props.index
            ? product.price * qtyInputRef.current.value
            : product.price * product.quantity
        )
        .reduce((a: any, b: any) => a + b),
      products: currentCartData.products.map((product: any) =>
        product.index === props.index
          ? { ...product, quantity: qtyInputRef.current.value }
          : product
      ),
    };

    setCurrentCartData(tempData);
    onClose();
    toast({
      title: `Updated the cart`,
      variant: "solid",
      isClosable: true,
      status: "success",
      duration: 4000,
      position: "top-right",
    });
  };

  return (
    <MotionFlex
      flexDir="column"
      alignItems="center"
      shadow="dark-lg"
      borderRadius="xl"
      whileHover={{ scale: 1.1, boxShadow: "0px 0px 15px #4264af" }}
      whileTap={{ scale: 1.0 }}
      cursor="pointer"
      minWidth="175px"
      justify="center"
      maxW="200px"
      m="1"
      mb="5"
      p="2"
      onClick={onOpen}
    >
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        returnFocusOnClose={false}
        preserveScrollBarGap={true}
        size="lg"
      >
        <ModalOverlay></ModalOverlay>
        <ModalContent bg={colorMode == "dark" ? "brand.900" : "brand.50"}>
          <ModalHeader>
            <Popover>
              <PopoverTrigger>
                <Button
                  bgColor="red"
                  colorScheme="red"
                  color="white"
                  size="sm"
                  gap="1"
                  //   onClick={() => setPopOpen(true)}
                >
                  <Trash />
                  <Text>Remove</Text>
                </Button>
              </PopoverTrigger>
              <PopoverContent bgColor={colorMode == "dark" ? "darkBg" : "#FFF"}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Hold on a second!</PopoverHeader>
                <PopoverBody>
                  Are you sure you want to remove this item?
                </PopoverBody>
                <PopoverFooter>
                  <Flex justifyContent="flex-end" gap="2">
                    <Button
                      colorScheme="red"
                      bgColor="red"
                      color="white"
                      onClick={handleDelete}
                    >
                      <Trash />
                      Delete
                    </Button>
                  </Flex>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="12">
            <Flex flexDir="column" gap="1">
              {props.image ? (
                <Img
                  src={props.image}
                  borderRadius="xl"
                  mb={5}
                  maxH={300}
                  maxW={500}
                />
              ) : (
                <VStack width="60%" alignSelf="center" mb="15%">
                  <Icon as={Camera} weight="duotone" fontSize={175} />
                  <Text fontSize={20}>No Image Provided</Text>
                </VStack>
              )}
              <Flex w="full" gap={10}>
                <Heading size="lg" noOfLines={1}>
                  {props.name}
                </Heading>
                <Heading size="xl" ml="auto" color="brand.500">
                  {"$" + props.price}
                </Heading>
              </Flex>
              <Text>{props.description}</Text>
              <Flex
                flexDir="column"
                mt="2"
                display={props.productType == "Services" ? "flex" : "none"}
              >
                <Text>Appointment time:</Text>
                <Text m="0">{formatISOtoAMPM(props.appointmentDates)}</Text>
              </Flex>
              <Text fontWeight="bold" mt="2">
                Notes: {props.notes || "N/A"}
              </Text>
              <Flex gap="5" mt="5">
                <NumberInputArea
                  qtyInputRef={qtyInputRef}
                  initialQty={props.quantity}
                />
                <Button
                  color="white"
                  bgColor="brand.orange"
                  colorScheme="orange"
                  onClick={handleUpdate}
                >
                  Update Cart
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex
        p={props.image ? 1 : "5"}
        borderRadius="xl"
        align="center"
        justify="center"
        w={200}
        h={150}
        position="relative"
      >
        {props.image ? (
          <Img
            src={props.image}
            w={"full"}
            h={150}
            maxW="95%"
            borderRadius="xl"
          />
        ) : (
          <VStack color="brand.500">
            <Icon as={Camera} weight="duotone" fontSize={80} />
            <Text fontSize={15}>No Image Provided</Text>
          </VStack>
        )}
        <Avatar
          position="absolute"
          display={props.quantity <= 1 ? "none" : "flex"}
          icon={
            <Text fontWeight="bold" color="white" fontSize="xl">
              {props.quantity}
            </Text>
          }
          size="md"
          bg="brand.400"
          left="80%"
          top="-10%"
        ></Avatar>
      </Flex>
      <Flex
        alignSelf="flex-start"
        p="2"
        alignItems="flex-start"
        gap="0"
        flexDir="column"
      >
        <Text fontWeight="bold" fontSize="lg">
          {props.name}
        </Text>
        <Text m="0" color="brand.500" fontWeight="bold">
          {"$" + props.price}
        </Text>
      </Flex>
    </MotionFlex>
  );
};

export default CartItem;

const NumberInputArea = (props: any) => {
  const [qtyInput, setQtyInput] = useState(1);

  return (
    <NumberInput defaultValue={props.initialQty} max={500} min={1}>
      <NumberInputField
        value={qtyInput}
        onChange={(e: any) => {
          setQtyInput(e.target.value);
        }}
        ref={props.qtyInputRef}
      />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

export const formatISOtoAMPM = (dates: any) => {
  var parsedDate1 = dayjs(dates[0]);
  var parsedDate2 = dayjs(dates[1]);
  return `${parsedDate1.format("llll")} - ${parsedDate2.format("llll")}`;
};
