/* eslint-disable react-hooks/exhaustive-deps */
import {
    Fade,
    useDisclosure,
    ScaleFade,
    Slide,
    SlideFade,
    Flex,
    Avatar,
    Image,
    Box,
    Text,
    HStack,
    VStack,
    Heading,
    Divider,
    Collapse,
    Button,
    ButtonGroup,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    useMediaQuery,
    Tooltip,
    useColorMode,
    IconButton,
    Portal,
    Popover,
    PopoverTrigger as OrigPopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    VisuallyHidden,
    useBreakpointValue,
    Img,
} from "@chakra-ui/react";
import { forwardRef, Fragment, useEffect, useRef, useState } from "react";
import EconicSVG from "../assets/images/EconicNetwork_BlueFooterText.svg";
import { MotionFlex } from "./CartItem";
import {
    At,
    BellRinging,
    Calendar,
    CaretLeft,
    Clock,
    Funnel,
    Info,
    MapPin,
    Package,
    Person,
    PersonSimpleWalk,
    Phone,
    Printer,
    Storefront,
    User,
    X as PLainX,
} from "phosphor-react";
import { motion, useMotionValue } from "framer-motion";
import {
    currentUserDataState,
    totalOrdersDataState,
    notificationAmountState,
    isLoggedInState,
} from "../recoilStates";
import { useRecoilState } from "recoil";
import { useForceUpdate, randomId, useInterval } from "@mantine/hooks";
import axios from "axios";
import dayjs from "dayjs";
import useSWR from "swr";
import useSound from "use-sound";
import notificationSound from "../public/notification.mp3";
import ReactToPrint from "react-to-print";
import { MantineProvider, SegmentedControl } from "@mantine/core";
import adze from "adze";
import { nanoid } from "nanoid";

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
    OrigPopoverTrigger;

const OrderNotifications = (props: any) => {
    const [totalOrderData, setTotalOrderData] =
        useRecoilState(totalOrdersDataState);
    const [notificationAmount, setNotificationAmount] = useRecoilState(
        notificationAmountState
    );
    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
    const [playNotification] = useSound(notificationSound);
    const [currentUserData] = useRecoilState(currentUserDataState);
    const [allOrderItems, setAllOrderItems] = useState([]);
    const [newOrderItems, setNewOrderItems] = useState([]);
    const [doneOrderItems, setDoneOrderItems] = useState([]);
    const [pendingOrderItems, setPendingOrderItems] = useState([]);
    const toast = useToast();
    const MotionBox = motion(Box);
    const MotionVStack = motion(VStack);
    const [isPhone, setIsPhone] = useState(false);
    const mq = useMediaQuery("(max-width: 700px)");
    useEffect(() => {
        setIsPhone(mq[0]);
    }, [mq]);
    const { data: newRecieptsData, error: newRecieptsError } = useSWR(
        isLoggedIn
            ? `/api/getOrderData?businessId=${currentUserData.PersonId}&status=1`
            : null,
        { refreshInterval: 10000, refreshWhenHidden: true }
    );
    const toastIdRef = useRef<any>();
    useEffect(() => {
        if (
            newOrderItems.length &&
            newRecieptsData?.length > newOrderItems.length
        ) {
            const newestOrderData = newRecieptsData[newRecieptsData.length - 1];
            toastIdRef.current = toast({
                render: () => (
                    <CustomOrderToast
                        newestOrderData={newestOrderData}
                        allRecieptsData={allRecieptsData}
                        mutateAllReciepts={mutateAllReciepts}
                        isPhone={isPhone}
                        toastIdRef={toastIdRef}
                    />
                ),
                variant: "solid",
                isClosable: true,
                duration: 5000,
                position: "top",
            });
            playNotification();
            mutateAllReciepts();
        }
    }, [newRecieptsData]);
    const {
        data: allRecieptsData,
        error: allRecieptsError,
        mutate: mutateAllReciepts,
    } = useSWR(
        isLoggedIn
            ? `/api/getOrderData?businessId=${currentUserData.PersonId}`
            : null
    );
    useEffect(() => {
        if (allRecieptsData && isLoggedIn) {
            getReciepts(
                currentUserData,
                setTotalOrderData,
                setPendingOrderItems,
                setNewOrderItems,
                setDoneOrderItems,
                allRecieptsData,
                mutateAllReciepts,
                setNotificationAmount
            );
        }
    }, [allRecieptsData]);

    const filterHandler = (e: any) => {
        let tempNew = totalOrderData
            .filter((order: any) => props.orderNotifTypeValue == order.Status)
            .map((order: any) => (
                <OrderItem
                    key={order.OrderId}
                    orderId={order.OrderId}
                    orderItems={order.OrderItems}
                    customerName={order.CustomerName}
                    status={order.Status}
                    email={order.CustomerEmail}
                    phone={order.CustomerPhone}
                    description={order.Description}
                    totalDue={order.TotalAmountDue}
                    merchandiseTotal={order.MerchandiseTotal}
                    fulfillmentAddress={order.FulfillmentAddress}
                    fulfillmentTime={order.FulfillmentTime}
                    fulfillmentMode={order.FulfillmentMode}
                    openNotifPopover={props.openNotifPopover}
                    paymentTime={order.PaymentTime}
                    tax={order.Tax}
                    tips={order.Tips}
                    notes={order.Notes}
                ></OrderItem>
            ));
        setNewOrderItems(
            tempNew
                .filter(
                    (elem: any) =>
                        elem.props.status == 1 &&
                        (elem.props.customerName.toLowerCase().includes(e.target.value) ||
                            elem.props.email.toLowerCase().includes(e.target.value) ||
                            elem.props.phone?.includes(e.target.value) ||
                            elem.props.orderId.toString().includes(e.target.value))
                )
                //.reverse()
        );
        setPendingOrderItems(
            tempNew
                .filter(
                    (elem: any) =>
                        elem.props.status == 2 &&
                        (elem.props.customerName.toLowerCase().includes(e.target.value) ||
                            elem.props.email.toLowerCase().includes(e.target.value) ||
                            elem.props.phone?.includes(e.target.value) ||
                            elem.props.orderId.toString().includes(e.target.value))
                )
                //.reverse()
        );
        setDoneOrderItems(
            tempNew
                .filter(
                    (elem: any) =>
                        elem.props.status == 3 &&
                        (elem.props.customerName.toLowerCase().includes(e.target.value) ||
                            elem.props.email.toLowerCase().includes(e.target.value) ||
                            elem.props.phone?.includes(e.target.value) ||
                            elem.props.orderId.toString().includes(e.target.value))
                )
                //.reverse()
        );
    };
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <Fragment>
            <MantineProvider theme={{ colorScheme: colorMode }}>
                <InputGroup>
                    <InputLeftElement>
                        <Funnel weight="fill" />
                    </InputLeftElement>
                    <Input
                        onChange={filterHandler}
                        placeholder="Filter by name, email, phone, order#"
                    ></Input>
                </InputGroup>
                <Text textAlign="center" m="2" color="brand.500" fontWeight="bold">
                    Swipe or click & drag an order to the left to quickly change the
                    status
                </Text>
                <MotionFlex p="5" pt="4" width="100%">
                    <VStack
                        width="100%"
                        alignItems="flex-start"
                        gap="4"
                        overflowX="hidden"
                    >
                        {allRecieptsData?.length
                            ? props.orderNotifTypeValue == 1
                                ? newOrderItems
                                : props.orderNotifTypeValue == 2
                                    ? pendingOrderItems
                                    : props.orderNotifTypeValue == 3
                                        ? doneOrderItems
                                        : null
                            : null}
                    </VStack>
                </MotionFlex>
            </MantineProvider>
        </Fragment>
    );
};
export default OrderNotifications;

const OrderItem = (props: any) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const [isPhone, setIsPhone] = useState(false);
    const mq = useMediaQuery("(max-width: 700px)");
    useEffect(() => {
        setIsPhone(mq[0]);
    }, [mq]);
    const toast = useToast();
    const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
    const {
        isOpen: modalOpen,
        onOpen: openModal,
        onClose: closeModal,
    } = useDisclosure();
    const ticketRef = useRef<any>();
    const currentOrder = props.allRecieptsData?.filter(
        (order: any) => order.OrderId == props.orderId
    );

    const [printPageSize, setPrintPageSize] = useState("A4");
    const allRecieptsWithoutCurrentOrder = props.allRecieptsData?.filter(
        (order: any) => order.OrderId !== props.orderId
    );

    // adze().debug(
    //   props.orderItems.map((item: any) =>
    //     item.Options?.replaceAll(/[^\d.,]/g, "").split(",")
    //   )
    // );

    const pageStyle = `
  @page {
    size: ${printPageSize == "reciept" ? "80mm 200mm" : "A4"};
  }
`;
    const segmentOrientation = useBreakpointValue([
        "horizontal",
        null,
        null,
        "vertical",
    ]);
    return (
        <Fragment>
            {/*//* Order Details Modal */}
            <Modal isOpen={modalOpen} onClose={closeModal} size="6xl">
                <ModalOverlay></ModalOverlay>
                <ModalContent bgColor={colorMode == "dark" ? "brand.900" : "#FFF"}>
                    <ModalHeader>
                        <Box
                            position="absolute"
                            right={[0, null, null, 0, -44]}
                            top={[-12, null, null, -12, 0]}
                        >
                            <SegmentedControlCustom
                                onChange={setPrintPageSize}
                                value={printPageSize}
                                data={[
                                    { value: "A4", label: "A4 (Normal Paper)" },
                                    { value: "reciept", label: 'Receipt paper (3"X8")' },
                                ]}
                                orientation={segmentOrientation}
                            ></SegmentedControlCustom>
                        </Box>
                        <ReactToPrint
                            pageStyle={pageStyle}
                            content={() => ticketRef.current}
                            trigger={() => (
                                <IconButton
                                    aria-label="Print"
                                    position="absolute"
                                    right={["auto", null, "312", "172", -16]}
                                    top={[-14, null, -14, -14, 20]}
                                    size="lg"
                                >
                                    <Printer size={25} weight="fill" />
                                </IconButton>
                            )}
                        ></ReactToPrint>
                        <Flex>
                            <Flex flexDir="column" gap="1">
                                <Flex gap="2">
                                    <Heading size="lg">Order Number</Heading>
                                    <Heading
                                        size="lg"
                                        color="brand.500"
                                    >{`#${props.orderId}`}</Heading>
                                </Flex>
                                <Tooltip label="Payment Time">
                                    <Heading size="sm">
                                        {dayjs(props.paymentTime).utc(true).format("LLLL")}
                                    </Heading>
                                </Tooltip>
                                <Flex alignItems="center" gap="2">
                                    <Info
                                        size={20}
                                        weight="fill"
                                        color={
                                            props.status == 1
                                                ? "red"
                                                : props.status == 2
                                                    ? "yellow"
                                                    : "#4264af"
                                        }
                                    ></Info>
                                    <Heading
                                        size="md"
                                        color={
                                            props.status == 1
                                                ? "red"
                                                : props.status == 2
                                                    ? "yellow"
                                                    : "brand.500"
                                        }
                                    >
                                        {props.status == 1
                                            ? "New"
                                            : props.status == 2
                                                ? "Pending"
                                                : "Done"}
                                    </Heading>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider mt="2"></Divider>
                    </ModalHeader>
                    <ModalCloseButton></ModalCloseButton>
                    <ModalBody gap="5" display="flex" flexDir="column">
                        <Flex
                            alignSelf="center"
                            gap="5"
                            mb="1"
                            flexDir={isPhone ? "column" : "row"}
                            alignItems="center"
                            textAlign="center"
                        >
                            <Flex alignItems="center" gap="1">
                                {props.fulfillmentMode == "At-Store" ||
                                    props.fulfillmentMode == "In-Store" ? (
                                    <Storefront size={20} weight="fill" color="#4264af" />
                                ) : props.fulfillmentMode == "Pickup" ? (
                                    <Person size={20} weight="fill" color="#4264af" />
                                ) : (
                                    <Package size={20} weight="fill" color="#4264af" />
                                )}
                                <Heading size="sm">{props.fulfillmentMode}</Heading>
                            </Flex>
                            <Tooltip
                                aria-label="Fulfillment Address"
                                label="Fulfillment Address"
                                placement="top"
                            >
                                <Flex alignItems="center" gap="1">
                                    <MapPin weight="fill" size={20} color="#4264af" />
                                    <Heading size="sm">{props.fulfillmentAddress}</Heading>
                                </Flex>
                            </Tooltip>

                            {props.orderItems[0].ProductType == "Services" ? (
                                <Flex alignItems="center" gap="1">
                                    <Tooltip label="Appointment Time">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            gap="1"
                                            display={
                                                props.orderItems[0].ServiceStartDate ? "flex" : "none"
                                            }
                                        >
                                            <Calendar size={20} color="#4264af" weight="fill" />
                                            <Heading size="sm">
                                                {Math.abs(
                                                    dayjs(props.orderItems[0].ServiceStartDate).diff(
                                                        dayjs(props.orderItems[0].ServiceEndDate),
                                                        "hour"
                                                    )
                                                ) > 23
                                                    ? `${dayjs(props.orderItems[0].ServiceStartDate)
                                                        .utc(false)
                                                        .format("llll")} - ${dayjs(
                                                            props.orderItems[0].ServiceEndDate
                                                        )
                                                            .utc(false)
                                                            .format("llll")}`
                                                    : `${dayjs(props.orderItems[0].ServiceStartDate)
                                                        .utc(false)
                                                        .format("llll")}`}
                                            </Heading>
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                            ) : (
                                <Flex alignItems="center" gap="1">
                                    <Tooltip label="Fulfillment Time">
                                        <Flex alignItems="center" justifyContent="center" gap="1">
                                            <Clock size={20} color="#4264af" weight="fill" />
                                            <Heading size="sm">{`${dayjs(props.fulfillmentTime).year() == 2000
                                                    ? props.paymentTime
                                                    : props.fulfillmentTime
                                                }`}</Heading>
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                            )}
                        </Flex>

                        <Flex gap="20" overflowX="auto">
                            <Table
                                variant={props.orderItems.length >= 3 ? "striped" : "simple"}
                                colorScheme="brand"
                                size={isPhone ? "sm" : "md"}
                            >
                                <Thead>
                                    <Tr>
                                        <Th>
                                            <Heading size={isPhone ? "xs" : "sm"}>
                                                Items Summary
                                            </Heading>
                                        </Th>
                                        <Th isNumeric>QTY</Th>
                                        <Th isNumeric>Price</Th>
                                        <Th isNumeric>Extras</Th>
                                        <Th isNumeric>Total</Th>
                                        <Th isNumeric>Notes</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {props.orderItems.map((item: any, i: any) => (
                                        <Tr key={randomId()}>
                                            <Td>
                                                <Flex alignItems="center" gap="4">
                                                    {item.ProductName}
                                                </Flex>
                                            </Td>
                                            <Td isNumeric>{"x" + item.Quantity}</Td>
                                            <Td isNumeric>{"$" + item.Price}</Td>
                                            <Td isNumeric>
                                                <Flex justify="flex-end" align="center" gap="1">
                                                    $
                                                    {item.Options?.map(
                                                        (option: any) => option.Amount
                                                    ).reduce((a: any, b: any) => a + b, 0) || 0}
                                                    {item.Options && (
                                                        <Popover placement="auto">
                                                            <PopoverTrigger>
                                                                <Info cursor="pointer" fontSize={15}></Info>
                                                            </PopoverTrigger>
                                                            <PopoverContent
                                                                w="fit-content"
                                                                bgColor={
                                                                    colorMode == "dark" ? "brand.900" : "brand.50"
                                                                }
                                                            >
                                                                <PopoverArrow />
                                                                <PopoverBody>
                                                                    <VStack minW={200} maxH={250} overflow="auto">
                                                                        <Heading size="md">
                                                                            Additional Options
                                                                        </Heading>
                                                                        {item.Options?.map((option: any) => (
                                                                            <Text
                                                                                key={nanoid()}
                                                                                fontSize={18}
                                                                            >{`${option.Name}   -   $${option.Amount}`}</Text>
                                                                        ))}
                                                                    </VStack>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    )}
                                                </Flex>
                                            </Td>
                                            <Td isNumeric>{"$" + item.Price * item.Quantity}</Td>
                                            <Td isNumeric>{item.Notes || "N/A"}</Td>
                                        </Tr>
                                    ))}
                                    <Tr display={props.tips ? "" : "none"}>
                                        <Td>Tips</Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td isNumeric>${props.tips}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Sales Tax</Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td isNumeric>${props.tax}</Td>
                                    </Tr>
                                </Tbody>
                                <Tfoot>
                                    <Tr>
                                        <Td>Total</Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td></Td>
                                        <Td isNumeric>
                                            $
                                            {(props.merchandiseTotal + props.tax + props.tips).toFixed(
                                                2
                                            )}
                                        </Td>
                                    </Tr>
                                </Tfoot>
                            </Table>
                        </Flex>
                        <Flex flexDir="column">
                            <Heading size="sm">Special Instructions:</Heading>
                            <Text>{props.notes || "N/A"}</Text>
                        </Flex>
                        <Heading size="xl">Customer Information</Heading>
                        <Flex
                            flexDir="column"
                            p="2"
                            border="1px"
                            borderColor="rgba(255,255,255,0.25)"
                            borderRadius="lg"
                            gap="2"
                            w={["full", null, "60%"]}
                            px="5"
                        >
                            <Tooltip label="Name" placement="left">
                                <HStack spacing={5}>
                                    <User size={30} weight="duotone" />
                                    <Text>{props.customerName}</Text>
                                </HStack>
                            </Tooltip>
                            <Divider></Divider>
                            <Tooltip label="Email" placement="left">
                                <HStack spacing={5}>
                                    <At size={30} weight="duotone" />
                                    <Text>{props.email}</Text>
                                </HStack>
                            </Tooltip>
                            <Divider></Divider>
                            <Tooltip label="Phone" placement="left">
                                <HStack spacing={5}>
                                    <Phone size={30} weight="duotone"></Phone>
                                    <Text>{props.phone}</Text>
                                </HStack>
                            </Tooltip>
                        </Flex>
                        <Heading mt="5">Change Status</Heading>
                        <Flex
                            alignItems="center"
                            gap={isPhone ? "3" : "5"}
                            pr={isPhone ? "0" : "10"}
                            width={isPhone ? "100%" : "80%"}
                            flexDir={isPhone ? "column" : "row"}
                            mb="5"
                        >
                            <Button
                                width="full"
                                colorScheme="red"
                                isDisabled={props.status == 1}
                                onClick={() => {
                                    closeModal();
                                    changeStatus(
                                        1,
                                        props.orderId,
                                        toast,
                                        props.mutateAllReciepts,
                                        currentOrder,
                                        allRecieptsWithoutCurrentOrder
                                    );
                                }}
                            >
                                New
                            </Button>
                            <Button
                                width="full"
                                colorScheme="yellow"
                                isDisabled={props.status == 2}
                                onClick={() => {
                                    closeModal();
                                    changeStatus(
                                        2,
                                        props.orderId,
                                        toast,
                                        props.mutateAllReciepts,
                                        currentOrder,
                                        allRecieptsWithoutCurrentOrder
                                    );
                                }}
                            >
                                Pending
                            </Button>
                            <Button
                                width="full"
                                colorScheme="green"
                                isDisabled={props.status == 3}
                                onClick={() => {
                                    closeModal();
                                    changeStatus(
                                        3,
                                        props.orderId,
                                        toast,
                                        props.mutateAllReciepts,
                                        currentOrder,
                                        allRecieptsWithoutCurrentOrder
                                    );
                                }}
                            >
                                Done
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <MotionFlex
                flexDir="column"
                width="100%"
                dragConstraints={{ top: 0, left: -275, right: 0, bottom: 0 }}
                dragElastic={0.1}
                position="relative"
                drag="x"
                userSelect="none"
            >
                <Collapse in={isOpen} startingHeight={90}>
                    <Flex
                        cursor="pointer"
                        width="100%"
                        gap={10}
                        position="relative"
                        onClick={onToggle}
                        textAlign="left"
                    >
                        <VStack alignItems="flex-start" ml="1">
                            <Box
                                border="2px"
                                borderRadius="xl"
                                p="1"
                                borderColor={
                                    props.status == 1
                                        ? "red"
                                        : props.status == 2
                                            ? "yellow"
                                            : props.status == 3
                                                ? "brand.green"
                                                : "white"
                                }
                            //   boxShadow="0px 0px 5px #4264af"
                            >
                                <Img
                                    src={
                                        colorMode == "dark" ? "/newEDark.svg" : "/logoOnlyLight.svg"
                                    }
                                    w={12}
                                    h={12}
                                />
                            </Box>
                            <Text fontWeight="bold">Order #{props.orderId}</Text>
                            <Flex>
                                <VStack
                                    justifyContent="center"
                                    mt="2"
                                    p="2"
                                    px="2"
                                    border="1px"
                                    borderRadius="md"
                                    borderColor="rgba(140, 140, 140, 0.5)"
                                    width="320px"
                                    maxWidth="75vw"
                                >
                                    <Text>Items</Text>
                                    <Divider></Divider>

                                    {props.orderItems.map((item: any) => (
                                        <Flex
                                            justifyContent="space-between"
                                            width="100%"
                                            key={Math.floor(Math.random() * 10000000)}
                                        >
                                            <Text w={'175px'}>{item.ProductName}</Text>
                                            <Text w={'60px'} m="0">Qty:{item.Quantity}</Text>
                                            <Text m="0">${item.Price}</Text>
                                        </Flex>
                                    ))}
                                    <Text pt="2">Customer Info</Text>
                                    <Divider></Divider>
                                    <Flex
                                        justifyContent="space-between"
                                        width="100%"
                                        // gap="20px"
                                        overflowX="auto"
                                        whiteSpace="nowrap"
                                    >
                                        <Text>{props.email}</Text>
                                        <Text m="0">{props.phone}</Text>
                                        <Text m="0">
                                            Total: ${props.merchandiseTotal + props.tax}
                                        </Text>
                                    </Flex>
                                </VStack>
                            </Flex>
                        </VStack>
                        <VStack
                            justifyContent="flex-start"
                            maxH={75}
                            alignItems="flex-start"
                            position="absolute"
                            left="40%"
                        >
                            <Text fontWeight="bold" fontSize="xl">
                                {`${props.orderItems?.length} ${props.orderItems?.length < 2 ? "Item" : "Items"}`}
                            </Text>
                            <Text>{props.customerName}</Text>
                        </VStack>
                        <MotionFlex
                            position="absolute"
                            left="90%"
                            top="23px"
                            initial={{ rotate: 0 }}
                            animate={{ rotate: isOpen ? -90 : 0 }}
                        >
                            <CaretLeft size={30} weight="fill" color="#4264af" />
                        </MotionFlex>
                    </Flex>
                    <Box p="1">
                        <Button
                            colorScheme="brand"
                            bgColor="brand.500"
                            color="white"
                            justifySelf="center"
                            mt="2"
                            width="full"
                            onClick={openModal}
                        >
                            View Full Details
                        </Button>
                    </Box>
                </Collapse>
                <Flex position="absolute" left="100%">
                    <ButtonGroup isAttached p="1">
                        <Button
                            height="70px"
                            width="80px"
                            colorScheme="red"
                            onClick={() => {
                                changeStatus(
                                    1,
                                    props.orderId,
                                    toast,
                                    props.mutateAllReciepts,
                                    currentOrder,
                                    allRecieptsWithoutCurrentOrder
                                );
                            }}
                        >
                            New
                        </Button>
                        <Button
                            height="70px"
                            width="80px"
                            colorScheme="yellow"
                            onClick={() => {
                                changeStatus(
                                    2,
                                    props.orderId,
                                    toast,
                                    props.mutateAllReciepts,
                                    currentOrder,
                                    allRecieptsWithoutCurrentOrder
                                );
                            }}
                        >
                            Pending
                        </Button>
                        <Button
                            height="70px"
                            width="80px"
                            colorScheme="green"
                            onClick={() => {
                                changeStatus(
                                    3,
                                    props.orderId,
                                    toast,
                                    props.mutateAllReciepts,
                                    currentOrder,
                                    allRecieptsWithoutCurrentOrder
                                );
                            }}
                        >
                            Done
                        </Button>
                    </ButtonGroup>
                </Flex>
            </MotionFlex>
            <Divider />
            <VisuallyHidden>
                <Flex flexDir="column" ref={ticketRef} color="black" p="0">
                    <VStack>
                        <Flex
                            position="absolute"
                            left={printPageSize == "reciept" ? "142px" : "2px"}
                            p={2}
                            bottom={printPageSize == "reciept" ? "-5px" : ""}
                            flexDir="column"
                            align="center"
                            justify="center"
                            w={100}
                        >
                            <EconicSVG
                                width={printPageSize == "reciept" ? "50px" : "100px"}
                            ></EconicSVG>
                        </Flex>
                        <HStack>
                            <Heading size={printPageSize == "reciept" ? "sm" : "xl"}>
                                Order Number 
                            </Heading>
                            <Heading
                                size={printPageSize == "reciept" ? "sm" : "xl"}
                                color="brand.500"
                            >{`#${props.orderId}`}</Heading>
                        </HStack>
                        <Heading size={printPageSize == "reciept" ? "xs" : "sm"}>
                            {dayjs(props.paymentTime)
                                .utc(true)
                                .format(printPageSize == "reciept" ? "llll" : "LLLL")}
                        </Heading>
                    </VStack>
                    <Flex
                        alignSelf="center"
                        gap="2"
                        flexDir={"column"}
                        alignItems="center"
                        textAlign="center"
                        mt="5"
                        mb="5"
                    >
                        <Flex alignItems="center" gap="1">
                            {props.fulfillmentMode == "At-Store" ||
                                props.fulfillmentMode == "In-Store" ? (
                                <Storefront
                                    size={printPageSize == "reciept" ? 10 : 20}
                                    weight="fill"
                                    color="#4264af"
                                />
                            ) : props.fulfillmentMode == "Pickup" ? (
                                <Person
                                    size={printPageSize == "reciept" ? 10 : 20}
                                    weight="fill"
                                    color="#4264af"
                                />
                            ) : (
                                <Package
                                    size={printPageSize == "reciept" ? 10 : 20}
                                    weight="fill"
                                    color="#4264af"
                                />
                            )}
                            <Heading size={printPageSize == "reciept" ? "xs" : "sm"}>
                                {props.fulfillmentMode}
                            </Heading>
                        </Flex>
                        <Tooltip
                            aria-label="Fulfillment Address"
                            label="Fulfillment Address"
                            placement="top"
                        >
                            <Flex alignItems="center" gap="1">
                                <MapPin
                                    weight="fill"
                                    size={printPageSize == "reciept" ? 10 : 20}
                                    color="#4264af"
                                />
                                <Heading size={printPageSize == "reciept" ? "xs" : "sm"}>
                                    {props.fulfillmentAddress}
                                </Heading>
                            </Flex>
                        </Tooltip>

                        {props.orderItems[0].ProductType == "Services" ? (
                            <Flex alignItems="center" gap="1">
                                <Tooltip label="Appointment Time">
                                    <Flex
                                        alignItems="center"
                                        justifyContent="center"
                                        gap="1"
                                        display={
                                            props.orderItems[0].ServiceStartDate ? "flex" : "none"
                                        }
                                    >
                                        <Calendar
                                            size={printPageSize == "reciept" ? 10 : 20}
                                            color="#4264af"
                                            weight="fill"
                                        />
                                        <Heading size={printPageSize == "reciept" ? "xs" : "sm"}>
                                            {`${dayjs(props.orderItems[0].ServiceStartDate)
                                                .utc(false)
                                                .format("LLLL")}`}
                                        </Heading>
                                    </Flex>
                                </Tooltip>
                            </Flex>
                        ) : (
                            <Flex alignItems="center" gap="1">
                                <Tooltip label="Fulfillment Time">
                                    <Flex alignItems="center" justifyContent="center" gap="1">
                                        <Clock
                                            size={printPageSize == "reciept" ? 10 : 20}
                                            color="#4264af"
                                            weight="fill"
                                        />
                                        <Heading
                                                size={printPageSize == "reciept" ? "xs" : "sm"}>
                                                {`${dayjs(props.fulfillmentTime).year() == 2000
                                                ? props.paymentTime
                                                : props.fulfillmentTime
                                            }`}</Heading>
                                    </Flex>
                                </Tooltip>
                            </Flex>
                        )}
                    </Flex>
                    <Table
                        variant={props.orderItems.length >= 3 ? "striped" : "simple"}
                        colorScheme="brand"
                        size={printPageSize == "reciept" ? "xs" : "sm"}
                        fontSize={printPageSize == "reciept" ? 6 : "2xl"}
                    >
                        <Thead>
                            <Tr>
                                <Th>
                                    <Heading fontSize={printPageSize == "reciept" ? 5 : "md"}>
                                        Items Summary
                                    </Heading>
                                </Th>
                                <Th isNumeric>QTY</Th>
                                <Th isNumeric>Price</Th>
                                <Th isNumeric>Extras</Th>
                                <Th isNumeric>Total</Th>
                                <Th isNumeric>Notes</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.orderItems.map((item: any, i: any) => (
                                <Tr key={randomId()}>
                                    <Td>
                                        <Flex alignItems="center" gap="4">
                                            {item.ProductName}
                                        </Flex>
                                    </Td>
                                    <Td isNumeric>{"x" + item.Quantity}</Td>
                                    <Td isNumeric>{"$" + item.Price}</Td>
                                    <Td isNumeric>
                                        $
                                        {item.Options?.map(
                                            (option: any) => option.Amount
                                        ).reduce((a: any, b: any) => a + b, 0) || 0}
                                    </Td>
                                    <Td isNumeric>{"$" + item.Price * item.Quantity}</Td>
                                    <Td isNumeric>
                                        {item.Options === null ? item.Notes === null ? "N/A" : item.Notes ?? "N/A" : item.Options + " | " + item.Notes || item.Notes || "N/A"}
                                    </Td>
                                </Tr>
                            ))}
                            <Tr display={props.tips ? "" : "none"}>
                                <Td>Tips</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>
                                <Td isNumeric>${props.tips}</Td>
                                <Td></Td>
                            </Tr>
                            <Tr>
                                <Td>Sales Tax</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>
                                <Td isNumeric>${props.tax}</Td>
                                <Td></Td>
                            </Tr>
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Td>Total</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>
                                <Td isNumeric>
                                    ${(props.merchandiseTotal + props.tax + props.tips).toFixed(2)}
                                </Td>
                                <Td></Td>
                            </Tr>
                        </Tfoot>
                    </Table>
                    <VStack></VStack>
                    <Heading size={printPageSize == "reciept" ? "xs" : "sm"} mt="10">
                        Special Instructions:
                    </Heading>
                    <Text mb="8">{props.Notes || "N/A"}</Text>
                    <VStack
                        mt="5"
                        align="left"
                        fontSize={printPageSize == "reciept" ? 8 : 14}
                    >
                        <Heading size={printPageSize == "reciept" ? "sm" : "lg"}>
                            Customer Information
                        </Heading>
                        <Text>Customer Name: {props.customerName}</Text>
                        <Text>Customer Email: {props.email}</Text>
                        <Text>Customer Phone: {props.phone}</Text>
                    </VStack>
                </Flex>
            </VisuallyHidden>
        </Fragment>
    );
};

const getReciepts = async (
    currentUserData: any,
    setTotalOrderData: any,
    setPendingOrderItems: any,
    setNewOrderItems: any,
    setDoneOrderItems: any,
    allRecieptsData: any,
    mutateAllReciepts: any,
    setNotificationAmount: any
) => {
    setTotalOrderData(allRecieptsData);
    var newItemsTemp = allRecieptsData?.filter((item: any) => item.Status == "1");
    var pendingItemsTemp = allRecieptsData?.filter(
        (item: any) => item.Status == "2"
    );
    var doneItemsTemp = allRecieptsData?.filter(
        (item: any) => item.Status == "3"
    );

    pendingItemsTemp = pendingItemsTemp.map((order: any) => {
        return (
            <OrderItem
                key={order.OrderId}
                orderId={order.OrderId}
                orderItems={order.OrderItems}
                customerName={order.CustomerName}
                status={order.Status}
                email={order.CustomerEmail}
                phone={order.CustomerPhone}
                description={order.Description}
                totalDue={order.TotalAmountDue}
                merchandiseTotal={order.MerchandiseTotal}
                fulfillmentAddress={order.FulfillmentAddress}
                fulfillmentTime={order.FulfillmentTime}
                paymentTime={order.PaymentTime}
                fulfillmentMode={order.FulfillmentMode}
                allRecieptsData={allRecieptsData}
                mutateAllReciepts={mutateAllReciepts}
                tax={order.Tax}
                tips={order.Tips}
                notes={order.Notes}
            ></OrderItem>
        );
    });
    const newOrderItemsElements = newItemsTemp.map((order: any) => (
        <OrderItem
            key={order.OrderId}
            orderId={order.OrderId}
            orderItems={order.OrderItems}
            customerName={order.CustomerName}
            status={order.Status}
            email={order.CustomerEmail}
            phone={order.CustomerPhone}
            description={order.Description}
            totalDue={order.TotalAmountDue}
            merchandiseTotal={order.MerchandiseTotal}
            fulfillmentAddress={order.FulfillmentAddress}
            fulfillmentTime={order.FulfillmentTime}
            fulfillmentMode={order.FulfillmentMode}
            paymentTime={order.PaymentTime}
            allRecieptsData={allRecieptsData}
            mutateAllReciepts={mutateAllReciepts}
            tax={order.Tax}
            tips={order.Tips}
            notes={order.Notes}
        ></OrderItem>
    ));
    doneItemsTemp = doneItemsTemp.map((order: any) => (
        <OrderItem
            key={order.OrderId}
            orderId={order.OrderId}
            orderItems={order.OrderItems}
            customerName={order.CustomerName}
            status={order.Status}
            email={order.CustomerEmail}
            phone={order.CustomerPhone}
            description={order.Description}
            totalDue={order.TotalAmountDue}
            merchandiseTotal={order.MerchandiseTotal}
            fulfillmentAddress={order.FulfillmentAddress}
            fulfillmentTime={order.FulfillmentTime}
            fulfillmentMode={order.FulfillmentMode}
            paymentTime={order.PaymentTime}
            allRecieptsData={allRecieptsData}
            mutateAllReciepts={mutateAllReciepts}
            tax={order.Tax}
            tips={order.Tips}
            notes={order.Notes}
        ></OrderItem>
    ));
    setDoneOrderItems(doneItemsTemp.reverse());
    setPendingOrderItems(pendingItemsTemp.reverse());
    setNotificationAmount(newItemsTemp.length);

    setNewOrderItems(newOrderItemsElements.reverse());
};

const changeStatus = async (
    status: any,
    orderId: any,
    toast: any,
    mutateAllReciepts: any,
    currentOrder: any,
    allRecieptsWithoutCurrentOrder: any
) => {
    const updatedLocalOrder = {
        ...currentOrder[0],
        Status: status.toString(),
        FulfillmentTime:
            status == 3
                ? dayjs.utc().format()
                : dayjs.utc("Jan 1, 2000 03:24:00").format(),
    };
    mutateAllReciepts(
        [...allRecieptsWithoutCurrentOrder, currentOrder],

        false
    );
    toast({
        title: `Changed Order #${orderId} status to ${status == 1 ? "New" : status == 2 ? "Pending" : "Done"}`,
        variant: "solid",
        isClosable: true,
        status: status == 1 ? "error" : status == 2 ? "warning" : "success",
        duration: 4000,
        position: "top-right",
    });

    const statusData = await axios.put(
        `/api/changeOrderStatus`,
        {
            status,
            orderId,
            //! Fulfillment Time is set to 2000 to indicate order is unfulfilled if status is 1 or 2.
            fulfillmentTime:
                status == 3
                    ? dayjs().utc(true).format()
                    : dayjs("Jan 1, 2000 03:24:00").format(),
        },
        {
            headers: {
                "Web-QA": process.env.APIHeaderKey || "",
            },
        }
    );
    mutateAllReciepts();
};

const CustomOrderToast = (props: any) => {
    const MotionBox = motion(Box);
    const MotionFlex = motion(Flex);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode } = useColorMode();
    const allRecieptsWithoutCurrentOrder = props.allRecieptsData?.filter(
        (order: any) => order.OrderId !== props.orderId
    );

    return (
        <>
            <MotionFlex
                p="5"
                borderRadius="xl"
                bgColor="brand.500"
                gap="5"
                alignItems="center"
                textAlign="center"
                display={isOpen ? "none" : "flex"}
                justifyContent="center"
            >
                <MotionBox
                    animate={{ rotate: [0, 25, 0, -25, 0] }}
                    transition={{
                        repeat: Infinity,
                        type: "tween",
                        ease: "linear",
                        stiffness: 0,
                        duration: 0.5,
                    }}
                >
                    <BellRinging size={40} weight="fill" color="#ECC94B" />
                </MotionBox>
                <Heading
                    color="white"
                    size="sm"
                >{`You recieved a new order! #${props.newestOrderData.OrderId}`}</Heading>
                {/* <Button
          onClick={() => {
            onOpen();
          }}
        >
          View Details
        </Button> */}
                <IconButton
                    aria-label="close"
                    size="xs"
                    icon={<PLainX weight="bold" />}
                    onClick={() => toast.close(props.toastIdRef.current)}
                />
            </MotionFlex>
            {/* <Modal
        isOpen={isOpen}
        onClose={() => {
          toast.closeAll();
          onClose();
        }}
        size="5xl"
      >
        <ModalOverlay></ModalOverlay>
        <ModalContent bgColor={colorMode == "dark" ? "#212324" : "#FFF"}>
          <ModalHeader>
            <Flex>
              <Flex flexDir="column" gap="1">
                <Flex gap="2">
                  <Heading size="lg">Order Number</Heading>
                  <Heading
                    size="lg"
                    color="brand.500"
                  >{`#${props.newestOrderData.OrderId}`}</Heading>
                </Flex>
                <Tooltip label="Payment Time">
                  <Heading size="sm">
                    {dayjs(props.newestOrderData.PaymentTime)
                      .utc(true)

                      .format("LLLL")}
                  </Heading>
                </Tooltip>
                <Flex alignItems="center" gap="2">
                  <Info size={20} weight="fill" color="red"></Info>
                  <Heading size="md" color="red">
                    New
                  </Heading>
                </Flex>
              </Flex>
            </Flex>
            <Divider mt="2"></Divider>
          </ModalHeader>
          <ModalCloseButton></ModalCloseButton>
          <ModalBody gap="5" display="flex" flexDir="column">
            <Flex
              alignSelf="center"
              gap="5"
              mb="1"
              flexDir={props.isPhone ? "column" : "row"}
              alignItems="center"
              textAlign="center"
            >
              <Flex alignItems="center" gap="1">
                {props.newestOrderData.FulfillmentMode == "At-Store" ||
                props.newestOrderData.FulfillmentMode == "In-Store" ? (
                  <Storefront size={20} weight="fill" color="#4264af" />
                ) : props.newestOrderData.FulfillmentMode == "Pickup" ? (
                  <Person size={20} weight="fill" color="#4264af" />
                ) : (
                  <Package size={20} weight="fill" color="#4264af" />
                )}
                <Heading size="sm">
                  {props.newestOrderData.FulfillmentMode}
                </Heading>
              </Flex>
              <Tooltip
                aria-label="Fulfillment Address"
                label="Fulfillment Address"
                placement="top"
              >
                <Flex alignItems="center" gap="1">
                  <MapPin weight="fill" size={20} color="#4264af" />
                  <Heading size="sm">
                    {props.newestOrderData.FulfillmentAddress}
                  </Heading>
                </Flex>
              </Tooltip>
              <Tooltip
                label={"Fulfillment Time"}
                aria-label="A tooltip"
                placement="top"
              >
                <Flex alignItems="center" gap="1">
                  <Clock size={20} weight="fill" color="#4264af" />
                  <Heading size="sm">
                    {new Date(
                      props.newestOrderData.FulfillmentTime
                    ).getFullYear() == 2000
                      ? "Unfulfilled"
                      : dayjs(props.newestOrderData.FulfillmentTime)
                          .utc(true)

                          .format("LLLL")}
                  </Heading>
                </Flex>
              </Tooltip>
            </Flex>

            <Flex gap="20" overflowX="auto">
              <Table
                variant={
                  props.newestOrderData.OrderItems.length >= 3
                    ? "striped"
                    : "simple"
                }
                colorScheme="brand"
                size={props.isPhone ? "sm" : "md"}
              >
                <Thead>
                  <Tr>
                    <Th>
                      <Heading size={props.isPhone ? "xs" : "sm"}>
                        Items Summary
                      </Heading>
                    </Th>
                    <Th isNumeric>QTY</Th>
                    <Th isNumeric>Price</Th>

                    <Th isNumeric>Total</Th>
                    <Th isNumeric>Notes</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {props.newestOrderData.OrderItems.map((item: any) => (
                    <Tr key={randomId()}>
                      <Td>
                        <Flex alignItems="center" gap="4">
                          {!props.isPhone ? <NewItemSVG width="30" /> : null}
                          {item.ProductName}
                        </Flex>
                      </Td>
                      <Td isNumeric>{"x" + item.Quantity}</Td>
                      <Td isNumeric>{"$" + item.Price}</Td>
                      <Td isNumeric>{"$" + item.Price * item.Quantity}</Td>
                      <Td isNumeric>{item.Notes || "N/A"}</Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Th>Sales Tax</Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th isNumeric>${props.newestOrderData.Tax}</Th>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Th>Total</Th>
                  <Th></Th>
                  <Th></Th>
                  <Th></Th>
                  <Th isNumeric>
                    $
                    {(
                      props.newestOrderData.MerchandiseTotal +
                      props.newestOrderData.Tax
                    ).toFixed(2)}
                  </Th>
                </Tfoot>
              </Table>
            </Flex>
            <Flex flexDir="column">
              <Heading size="sm">Special Instructions:</Heading>
              <Text>{props.newestOrderData.Notes || "N/A"}</Text>
            </Flex>
            <Heading size="xl">Customer Information</Heading>
            <Flex
              flexDir="column"
              p="2"
              border="1px"
              borderColor="rgba(255,255,255,0.25)"
              borderRadius="lg"
              gap="2"
              px="5"
            >
              <HStack justifyContent="space-between">
                <Heading size="sm">Customer Name:</Heading>
                <Text>{props.newestOrderData.CustomerName}</Text>
              </HStack>
              <Divider></Divider>
              <HStack justifyContent="space-between">
                <Heading size="sm">Customer Email:</Heading>
                <Text>{props.newestOrderData.Email}</Text>
              </HStack>
              <Divider></Divider>
              <HStack justifyContent="space-between">
                <Heading size="sm">Customer Phone:</Heading>
                <Text>{props.newestOrderData.Phone}</Text>
              </HStack>
            </Flex>
            <Heading mt="5">Change Status</Heading>
            <Flex
              alignItems="center"
              gap={props.isPhone ? "2" : "5"}
              pr={props.isPhone ? "0" : "10"}
              width={props.isPhone ? "100%" : "80%"}
              mb="5"
            >
              <Button
                width="full"
                colorScheme="red"
                isDisabled={props.newestOrderData.Status == 1}
                onClick={() => {
                  onClose();
                  changeStatus(
                    1,
                    props.newestOrderData.OrderId,
                    toast,
                    props.mutateAllReciepts,
                    props.newestOrderData,
                    allRecieptsWithoutCurrentOrder
                  );
                }}
              >
                New
              </Button>
              <Button
                width="full"
                colorScheme="yellow"
                isDisabled={props.status == 2}
                onClick={() => {
                  onClose();
                  changeStatus(
                    2,
                    props.orderId,
                    toast,
                    props.mutateAllReciepts,
                    props.newestOrderData,
                    allRecieptsWithoutCurrentOrder
                  );
                }}
              >
                Pending
              </Button>
              <Button
                width="full"
                colorScheme="brand"
                isDisabled={props.status == 3}
                onClick={() => {
                  onClose();
                  changeStatus(
                    3,
                    props.orderId,
                    toast,
                    props.mutateAllReciepts,
                    props.newestOrderData,
                    allRecieptsWithoutCurrentOrder
                  );
                }}
              >
                Done
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal> */}
        </>
    );
};

const SegmentedControlCustom = (props: any) => {
    return <SegmentedControl {...props}></SegmentedControl>;
};
